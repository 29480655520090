import {
  TextInput,
  Button,
  ButtonVariant,
  TextInputWidth,
  TextInputSize,
  LabelSize,
  Select,
  Label,
  SelectSize,
  DatePicker,
  DatePickerSize,
  Icon,
  IconName,
  ButtonIconAlign,
  EventValue
} from "@eightfold.ai/octuple";
import React, { useEffect, useState, useContext } from "react";
import { secondaryButtonStyle } from "../../commons/commonStyles";
import { useNavigate } from "react-router";
import dayjs from 'dayjs';
import { validateFields, validationOptions } from '../../ValidationSchema';
import { globalContext } from "../../store";
import { checkSubDropDownValues, updateDemographicData, validateDynamicForm } from '../../commons/commonFunctions'
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);


const Role = (props: any) => {

  const navigate = useNavigate();
  const { globalState, dispatch } = useContext(globalContext);
  const [jobFunction, setJobFunction] = useState<any>("");
  const [jobSubFuncOptions, setJobSubFuncOptions] = useState<any>([]);
  const [jobSubFunction, setJobSubFunction] = useState<any>("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobLevel, setJobLevel] = useState("");
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [yearsAtJobLevel, setYearsAtJobLevel] = useState("");
  const [yearsInLeadershipRole, setYearsInLeadershipRole] = useState("");
  const [directReports, setDirectReports] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [describeJobSubFunc, setDescribeJobSubFunc] = useState(false);
  const [jobSubFuncTxt, setjobSubFuncTxt] = useState("");
  const [disableSubFunc, setDisableSubFunc] = useState(true);

  const [UnformattedStartDate, setUnformattedStartDate] = useState<EventValue<dayjs.Dayjs> | null>(null);
  const [UnformattedEndDate, setUnformattedEndDate] = useState<EventValue<dayjs.Dayjs> | null>(null);
  const [errorMsg, setErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const otherTxt = 'Other (please specify)';
  const notApplicableList = [{ text: 'Not Applicable', value: 'Not Applicable' }];
  const otherOptionList = [{ text: `${otherTxt}`, value: `${otherTxt}` }];

  useEffect(() => {
    if (props) {
      setJobLevel(props?.jobLevel);
      setJobTitle(props?.jobTitle);
      setYearsAtJobLevel(props?.yearsAtJobLevel);
      setJobFunction(props?.jobFunction);
      if (props.jobSubFunction && props?.jobSubFunction?.includes('~')) {
        setjobSubFuncTxt(props?.jobSubFunction.replace('OTH~', ''));
        setJobSubFunction(otherTxt);
      } else if (props.jobSubFunction) {
        setJobSubFunction(props.jobSubFunction);
      }

      setYearsInLeadershipRole(props?.yearsInLeadershipRole);
      setDirectReports(props?.directReports);
      setTeamSize(props?.teamSize);
      if (props?.hireDate) {
        setUnformattedStartDate(dayjs(props?.hireDate))
        setStartDate(new Date(props?.hireDate));
      }
      if (props?.terminationDate) {
        setUnformattedEndDate(dayjs(props?.terminationDate))
        setEndDate(new Date(props.terminationDate));
      }
    }
  }, [props]);

  useEffect(() => {
    if (jobSubFuncOptions && (jobSubFuncOptions?.[0]?.text === 'Not Applicable')) {
      setJobSubFunction('Not Applicable');
    } else if (jobSubFuncOptions && (jobSubFuncOptions?.[0]?.text === otherTxt)) {
      setJobSubFunction(otherTxt);
    }
    else if (jobSubFuncOptions && (jobSubFuncOptions?.[0]?.text !== 'Not Applicable') && jobFunction && (jobSubFunction === 'Not Applicable')) {
      setJobSubFunction('');
    }

  }, [jobSubFuncOptions]);



  const changeSubFunction = (value: any) => {
    if (jobSubFunction !== 'Not Applicable') {
      setJobSubFunction(value?.[0]);
    }

    if (value?.[0] === otherTxt) {
      setDescribeJobSubFunc(true);
    } else {
      setDescribeJobSubFunc(false);
    }
  };

  const changeJobFunction = (value: string) => {
    setJobFunction(value?.[0]);
    const options = checkSubDropDownValues(value?.[0], 'Job Sub-Function', globalState?.lookUpData);
    if (value?.[0] && options && options?.length > 0) {
      setjobSubFuncTxt('');
      if (value?.[0] === otherTxt) {
        setJobSubFuncOptions(otherOptionList);
        setDisableSubFunc(false);
        if (props.jobSubFunction && props.jobSubFunction?.includes('~')) {
          setjobSubFuncTxt(props.jobSubFunction.replace('OTH~', ''));
        }
        return;
      }
      setJobSubFuncOptions(options);
      setDisableSubFunc(false);
    } else if (value?.[0]) {
      setJobSubFuncOptions(notApplicableList);
      setDisableSubFunc(true);
    }
    if (!value?.[0]) {
      setDisableSubFunc(true);
    }
  };

  const validationForEndDate = () => {
    let endDateMsg;
    let jobSubFuncDesMsg;

    if (Date.parse(endDate) <= Date.parse(startDate)) {
      endDateMsg = "End date should be greater than start date";
    }

    if (jobSubFunction === otherTxt) {
      jobSubFuncDesMsg = validateFields.validate(
        { textField: jobSubFuncTxt || "" },
        validationOptions
      )?.error?.details[0]?.message;
    }
    return { endDateMsg, jobSubFuncDesMsg };
  };

  const validateFormData = () => {
    const fieldConfigs = [
      { name: 'showJobLevel', value: jobLevel, type: 'dropDown' },
      { name: 'showJobTitle', value: jobTitle, type: 'textField' },
      { name: 'showHireDate', value: startDate && startDate !== 'Invalid Date' ? 'true' : '', type: 'dropDown' },
      { name: 'showJobFunction', value: jobFunction, type: 'dropDown' },
      { name: 'showJobSubFunction', value: jobSubFunction, type: 'dropDown' },
      { name: 'showYearsAtJobLevel', value: yearsAtJobLevel, type: 'dropDown' },
      { name: 'showYearsInLeadershipRole', value: yearsInLeadershipRole, type: 'dropDown' },
      { name: 'showDirectReports', value: directReports, type: 'dropDown' },
      { name: 'showTeamSize', value: teamSize, type: 'dropDown' },
    ];

    const filteredFieldConfigs = fieldConfigs.filter(field => props?.showFields?.[field.name] === true);  
    
    const customValidations = {
      endDateMsg: () => validationForEndDate().endDateMsg,
      jobSubFuncDesMsg: () => validationForEndDate().jobSubFuncDesMsg,
    };
    
    const isFormInvalid = validateDynamicForm(filteredFieldConfigs, customValidations, setErrors);
    return isFormInvalid
  };

  const saveRoleData = () => {
    if (validateFormData()) return;
    const payload: any = {
      demographicSurveyData: {
        jobTitle: jobTitle,
        jobLevel: jobLevel,
        yearsAtJobLevel: yearsAtJobLevel,
        yearsInLeadershipRole: yearsInLeadershipRole,
        directReports: directReports,
        jobSubFunction: (jobSubFunction === otherTxt) ? `OTH~${jobSubFuncTxt}` : jobSubFunction,
        jobFunction: jobFunction,
        hireDate: startDate !== 'Invalid Date' ? startDate : '',
        terminationDate: endDate !== 'Invalid Date' ? endDate : '',
        teamSize: teamSize
      }

    }
    if (startDate !== 'Invalid Date') {
      payload.demographicSurveyData['hireDate'] = startDate
    }

    if (endDate !== 'Invalid Date') {
      payload.demographicSurveyData['terminationDate'] = endDate;
    }

    const updatedDataForContext: any = {
      demographicSurveyData: {
        ...globalState?.leaderData?.demographicSurveyData,
        jobTitle: jobTitle,
        jobLevel: jobLevel,
        yearsAtJobLevel: yearsAtJobLevel,
        yearsInLeadershipRole: yearsInLeadershipRole,
        directReports: directReports,
        jobSubFunction: (jobSubFunction === otherTxt) ? `OTH~${jobSubFuncTxt}` : jobSubFunction,
        jobFunction: jobFunction,
        hireDate: startDate !== 'Invalid Date' ? startDate : '',
        terminationDate: endDate !== 'Invalid Date' ? endDate : '',
        teamSize: teamSize
      }
    };

    const newContextData = { ...globalState?.leaderData, ...updatedDataForContext }
    const redirectUrl = '/register-company';
    updateDemographicData({ payload, leaderProfileId: props.leaderProfileId, redirectUrl, setErrorMsg, newContextData, dispatch, navigate, setShowLoader });
  }

  const backtoAccountPage = () => {
    navigate('/register-account');
  }

  const disabledEndDate = (current: any) => {
    return current && current < dayjs(UnformattedStartDate).endOf('day');
  };

  const disabledStartDate = (current: any) => {
    return current && current > dayjs().endOf('day');
  };

  return (
    <div className="accountWrapper">
      <h1>Role</h1>
      <h2>About your current or most recent role</h2>
      {props?.jobLevelOptions?.length > 0 &&
        <>
          {props?.showFields?.showJobTitle && 
            <div className="fieldWrap">
              <TextInput
                htmlType="text"
                id="jobTitle"
                clearable
                labelProps={{
                  text: "What is your job title?",
                  size: LabelSize.Medium,
                }}
                maxlength={100}
                minlength={0}
                name="jobTitle"
                value={jobTitle}
                onChange={(e: any) => {
                  setJobTitle(e.target.value);
                }}
                inputWidth={TextInputWidth.fill}
                classNames="inputTextField"
                size={TextInputSize.Large}
                status={errors?.showJobTitleStatus}
              />
              {errors?.showJobTitleMsg && <span className="errorMessage">{errors?.showJobTitleMsg}</span>}

            </div>
          }

          {props?.showFields?.showJobLevel &&
            <div className="fieldWrap">
              <Label htmlFor="jobLevel" size={LabelSize.Medium} text="What is your job level?" />
              <Select
                id="jobLevel"
                data-test-id="jobLevel"
                defaultValue={props?.jobLevel}
                onOptionsChange={(e: any) => {
                  setJobLevel(e?.[0]);
                }}
                size={SelectSize.Large}
                classNames="selectField"
                options={props?.jobLevelOptions}
                status={errors?.showJobLevelStatus}
              />
              {errors?.showJobLevelMsg && <span className="errorMessage">{errors?.showJobLevelMsg}</span>}
            </div>
          }
          
          {props?.showFields?.showHireDate &&
            <div className="fieldWrap">
              <Label htmlFor="startDate" size={LabelSize.Medium} text="Start Date" />
              <DatePicker
                id="startDate"
                size={DatePickerSize.Large}
                classNames="datePickerField"
                disabledDate={disabledStartDate}
                status={errors?.showHireDateStatus}
                value={UnformattedStartDate}
                onChange={(value: EventValue<dayjs.Dayjs> | null) => {
                  setUnformattedStartDate(value);
                  setStartDate(dayjs(value).format('YYYY-MM-DDTHH:mm:ss'));
                }}
              />
              {errors?.showHireDateMsg && <span className="errorMessage">{errors?.showHireDateMsg}</span>}
            </div>
          }

          {props?.showFields?.showTerminationDate &&
            <div className="fieldWrap">
              <Label htmlFor="endDate" size={LabelSize.Medium} text="End Date" />
              <DatePicker
                id="endDate"
                size={DatePickerSize.Large}
                disabledDate={disabledEndDate}
                status={errors?.endDateStatus}
                value={UnformattedEndDate}
                onChange={(value: EventValue<dayjs.Dayjs> | null) => {
                  setUnformattedEndDate(value);
                  setEndDate(dayjs(value).format('YYYY-MM-DDTHH:mm:ss'));
                }}
                classNames="datePickerField"
              />
              {errors?.endDateMsg && <span className="errorMessage">{errors?.endDateMsg}</span>}
            </div>
          }
          
          {props?.showFields?.showYearsAtJobLevel &&
            <div className="fieldWrap">
              <Label
                htmlFor="yearsAtJobLevel"
                size={LabelSize.Medium}
                text="How many years total have you been at your current job level?"
              />
              <Select
                id="yearsAtJobLevel"
                data-test-id="yearsAtJobLevel"
                onOptionsChange={(e: any) => {
                  setYearsAtJobLevel(e?.[0]);
                }}
                size={SelectSize.Large}
                classNames="selectField"
                options={props?.yearsAtJobLevelOptions}
                defaultValue={props?.yearsAtJobLevel}
                status={errors?.showYearsAtJobLevelStatus}
              />
              {errors?.showYearsAtJobLevelMsg && <span className="errorMessage">{errors?.showYearsAtJobLevelMsg}</span>}
            </div>
          }

          {props?.showFields?.showJobFunction &&
            <div className="fieldWrap">
              <Label
                htmlFor="jobFunction"
                size={LabelSize.Medium}
                text="What is your job function?"
              />
              <Select
                id="jobFunction"
                data-test-id="jobFunction"
                onOptionsChange={(value: any) => changeJobFunction(value)}
                size={SelectSize.Large}
                status={errors?.showJobFunctionStatus}
                classNames="selectField"
                options={props?.jobFunctionOptions}
                defaultValue={props?.jobFunction}
              />
              {errors?.showJobFunctionMsg && <span className="errorMessage">{errors?.showJobFunctionMsg}</span>}

            </div>
          }

          {props?.showFields?.showJobSubFunction && jobSubFuncOptions?.length > 0 && 
            <div className="fieldWrap">
              <Label
                id="subfunction"
                size={LabelSize.Medium}
                text="What is your job function sub-function?"
              />
              <Select
                area-labelledby="subfunction"
                id="jobSubFunction"
                size={SelectSize.Large}
                onOptionsChange={(value: any) => changeSubFunction(value)}
                classNames="selectField"
                options={jobSubFuncOptions !== undefined ? jobSubFuncOptions : []}
                defaultValue={jobSubFunction ?? "Select"}
                status={errors?.showJobSubFunctionStatus}
                disabled={disableSubFunc ? true : false}
              />
              {errors?.showJobSubFunctionMsg && <span className="errorMessage">{errors?.showJobSubFunctionMsg}</span>}
            </div>
          }

          {describeJobSubFunc &&
            <div className="fieldWrap">
              <TextInput
                htmlType="text"
                id="jobSubFuncDescribe"
                clearable
                maxlength={100}
                minlength={0}
                name="jobSubFuncDescribe"
                onChange={(e) => {
                  setjobSubFuncTxt(e.target.value);
                }}
                value={jobSubFuncTxt}
                defaultValue={jobSubFuncTxt}
                inputWidth={TextInputWidth.fill}
                classNames="inputTextField"
                size={TextInputSize.Large}
                status={errors?.jobSubFuncDesMsg ? 'error' : 'validating'}
              />
              {errors?.jobSubFuncDesMsg && (
                <span className="errorMessage">{errors?.jobSubFuncDesMsg}</span>
              )}
            </div>
          }

          {props?.showFields?.showYearsInLeadershipRole &&
            <div className="fieldWrap">
              <Label
                htmlFor="yearsInLeadershipRole"
                size={LabelSize.Medium}
                text="How many years have you been in a leadership role?"
              />
              <Select
                id="yearsInLeadershipRole"
                data-test-id="yearsInLeadershipRole"
                onOptionsChange={(e: any) => {
                  setYearsInLeadershipRole(e?.[0]);
                }}
                size={SelectSize.Large}
                classNames="selectField"
                options={props?.yearsInLeadershipRoleOptions}
                defaultValue={props?.yearsInLeadershipRole}
                status={errors?.showYearsInLeadershipRoleStatus}
              />
              {errors?.showYearsInLeadershipRoleMsg && <span className="errorMessage">{errors?.showYearsInLeadershipRoleMsg}</span>}

            </div>
          }

          {props?.showFields?.showDirectReports &&
            <div className="fieldWrap">
              <Label
                htmlFor="directReports"
                size={LabelSize.Medium}
                text="How many people directly report to you?"
              />
              <Select
                id="directReports"
                data-test-id="directReports"
                onOptionsChange={(e: any) => {
                  setDirectReports(e?.[0]);
                }}
                size={SelectSize.Large}
                status={errors?.showDirectReportsStatus}
                classNames="selectField"
                options={props?.directReportsOptions}
                defaultValue={props?.directReports}
              />
              {errors?.showDirectReportsMsg && <span className="errorMessage">{errors?.showDirectReportsMsg}</span>}
            </div>
          }

          {props?.showFields?.showTeamSize &&
            <div className="fieldWrap">
              <Label
                htmlFor="teamSize"
                size={LabelSize.Medium}
                text="What is the size of your work team?"
              />
              <Select
                id="teamSize"
                data-test-id="teamSize"
                onOptionsChange={(e: any) => {
                  setTeamSize(e?.[0]);
                }}
                size={SelectSize.Large}
                status={errors?.showTeamSizeStatus}
                classNames="selectField"
                options={props?.teamSizeOptions}
                defaultValue={props?.teamSize}
              />
              {errors?.showTeamSizeMsg && <span className="errorMessage">{errors?.showTeamSizeMsg}</span>}
            </div>
          }


          <div className="fieldWrap-Btn">
            <Icon
              classNames="secondaryButton"
              color="#BA3A19"
              data-test-id="myIconTestId"
              description="My icon description."
              id="myIconId"
              path={IconName.mdiChevronLeft}
              role="presentation"
              rotate={0}
              size="20px"
              title="My icon title."
            />
            <Button
              text="Back"
              variant={ButtonVariant.SystemUI}
              classNames="backButton"
              onClick={backtoAccountPage}
              transparent={true}
            />
            <Button
              text="Continue"
              variant={ButtonVariant.Secondary}
              classNames="secondaryButton"
              style={secondaryButtonStyle}
              onClick={() => { saveRoleData() }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />
          </div>
        </>
      }
      {errorMsg && <span className="apiErrorMessage">An error occurred</span>}

    </div>
  );
};

export default Role;
