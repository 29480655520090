import {
  Button,
  ButtonVariant,
  Select,
  Label,
  LabelSize,
  SelectSize,
  Icon,
  IconName,
  ButtonIconAlign,
  TextInput,
  TextInputWidth,
  TextInputSize,
} from "@eightfold.ai/octuple";
import React, { useState, useEffect, useContext } from "react";
import { secondaryButtonStyle } from "../../commons/commonStyles";
import { useNavigate } from "react-router";
import { globalContext } from "../../store";

import { checkSubDropDownValues, updateDemographicData, validateDynamicForm } from '../../commons/commonFunctions'
import { validateFields, validationOptions } from "../../ValidationSchema";


const Company = (props: any) => {
  const navigate = useNavigate();

  const { globalState, dispatch } = useContext(globalContext);
  const [orgType, setOrgType] = useState<any>("");
  const [orgStructure, setOrgStructure] = useState<any>("");
  const [orgSize, setOrgSize] = useState<any>("");
  const [orgCycle, setOrgCycle] = useState<any>("");
  const [orgRevenue, setOrgRevenue] = useState<any>("");
  const [workingEnvironment, setWorkingEnvironment] = useState<any>("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [industry, setIndustry] = useState("");
  const [industrySector, setIndustrySector] = useState("");
  const [describeIndustrySec, setDescribeIndustrySec] = useState(false);
  const [industrySecTxt, setindustrySecTxt] = useState("");
  const [errors, setErrors] = useState<any>({});

  const [industrySectOptions, setIndustrySectOptions] = useState<any>([]);
  const [disableIndustrySec, setDisableIndustrySec] = useState(true);
  const otherTxt = 'Other';
  const notApplicableList = [{ text: 'Not Applicable', value: 'Not Applicable' }];
  const otherOptionList = [{ text: `${otherTxt}`, value: `${otherTxt}` }];

  useEffect(() => {
    if (props) {
      setOrgType(props?.orgType);
      setOrgStructure(props?.orgStructure);
      setOrgSize(props?.orgSize);
      setOrgCycle(props?.orgCycle);
      setOrgRevenue(props?.orgRevenue);
      setWorkingEnvironment(props?.workingEnvironment);
      setIndustry(props?.industry);
      if (props.industrySector && props?.industrySector?.includes('~')) {
        setindustrySecTxt(props?.industrySector.replace('OTH~', ''));
        setIndustrySector(otherTxt);
      } else if (props.industrySector) {
        setIndustrySector(props.industrySector);
      }

    }
  }, [props]);

  useEffect(() => {
    if (industrySectOptions && industrySectOptions?.[0]?.text === 'Not Applicable') {
      setIndustrySector('Not Applicable');
    } else if (industrySectOptions && (industrySectOptions?.[0]?.text === otherTxt)) {
      setIndustrySector(otherTxt);
    } else if (industrySectOptions && (industrySectOptions?.[0]?.text !== 'Not Applicable') && industry && (industrySector === 'Not Applicable')) {
      setIndustrySector('');
    }
  }, [industrySectOptions]);


  const changeIndustry = (value: any) => {
    setIndustry(value?.[0]);
    const options = checkSubDropDownValues(value?.[0], 'Industry Sector', globalState?.lookUpData);
    if (value?.[0] && options && options?.length > 0) {
      if (value?.[0] === otherTxt) {
        setIndustrySectOptions(otherOptionList);
        setDisableIndustrySec(false);
        setDescribeIndustrySec(true);
        if (props.industrySector && props.industrySector?.includes('~')) {
          setindustrySecTxt(props.industrySector.replace('OTH~', ''));
        }
        return;
      }
      setIndustrySectOptions(options);
      setDisableIndustrySec(false);
      setindustrySecTxt('');
    } else if (value?.[0]) {
      setIndustrySectOptions(notApplicableList);
      setDisableIndustrySec(true);
    }
    if (!value?.[0]) {
      setDisableIndustrySec(true);
    }
  };

  const changeIndustrySector = (value: any) => {
    if (industrySector !== 'Not Applicable') {
      setIndustrySector(value?.[0]);
    }

    if (value?.[0] === otherTxt) {
      setDescribeIndustrySec(true);
    } else {
      setDescribeIndustrySec(false);
    }
  };


  const validationForIndustrySector = () => {
    let industrySecDesMsg;

    if (industrySector === otherTxt) {
      industrySecDesMsg = validateFields.validate({ textField: industrySecTxt || '' }, validationOptions)?.error?.details[0]?.message;
    }

    return { industrySecDesMsg };
  };

  const validateFormData = () => {

    const fieldConfigs = [
      { name: 'showIndustry', value: industry, type: 'dropDown' },
      { name: 'showIndustrySector', value: industrySector, type: 'dropDown' },
      { name: 'showOrgType', value: orgType, type: 'dropDown' },
      { name: 'showOrgStructure', value: orgStructure, type: 'dropDown' },
      { name: 'showOrgSize', value: orgSize, type: 'dropDown' },
      { name: 'showOrgCycle', value: orgCycle, type: 'dropDown' },
      { name: 'showOrgRevenue', value: orgRevenue, type: 'dropDown' },
      { name: 'showWorkingEnvironment', value: workingEnvironment, type: 'dropDown' },
    ];

    const filteredFieldConfigs = fieldConfigs?.filter(field => props?.showFields?.[field.name] === true);

    const customValidations = {
      industrySecDesMsg: () => validationForIndustrySector()?.industrySecDesMsg,
    };

    const isFormInvalid = validateDynamicForm(filteredFieldConfigs, customValidations, setErrors);
    return isFormInvalid
  };

  const saveCompanyData = () => {
    if (validateFormData()) return;
    const payload = {
      demographicSurveyData: {
        orgType: orgType,
        orgStructure: orgStructure,
        orgSize: orgSize,
        orgCycle: orgCycle,
        orgRevenue: orgRevenue,
        workingEnvironment: workingEnvironment,
        industry: industry,
        industrySector: (industrySector === otherTxt) ? `OTH~${industrySecTxt}` : industrySector,
      }
    };

    const updatedDataForContext: any = {
      demographicSurveyData: {
        ...globalState?.leaderData?.demographicSurveyData,
        orgType: orgType,
        orgStructure: orgStructure,
        orgSize: orgSize,
        orgCycle: orgCycle,
        orgRevenue: orgRevenue,
        workingEnvironment: workingEnvironment,
        industry: industry,
        industrySector: (industrySector === otherTxt) ? `OTH~${industrySecTxt}` : industrySector,
      }
    };

    const newContextData = { ...globalState?.leaderData, ...updatedDataForContext }
    const redirectUrl = '/register-personal'
    updateDemographicData({ payload, leaderProfileId: props.leaderProfileId, redirectUrl, setErrorMsg, newContextData, dispatch, navigate, setShowLoader });
  };

  const backtoRolePage = () => {
    navigate('/register-role');
  }

  return (
    <div className="accountWrapper">
      <h1>Company</h1>
      <h2>About your current or most recent company</h2>

      {props?.orgTypeOptions?.length > 0 &&
        <>
          {props?.showFields?.showIndustry &&
            <div className="fieldWrap">
              <Label htmlFor="industry" size={LabelSize.Medium} text="What industry do you work in?" />
              <Select
                id="industry"
                data-test-id="industry"
                onOptionsChange={(value: any) => changeIndustry(value)}
                size={SelectSize.Large}
                classNames="selectField"
                options={props?.industryOptions}
                defaultValue={props?.industry}
                status={errors?.showIndustryStatus}
              />
              {errors?.showIndustryMsg && <span className="errorMessage">{errors?.showIndustryMsg}</span>}
            </div>
          }
          {industrySectOptions?.length > 0 &&
            <>   {props?.showFields?.showIndustrySector &&
              <div className="fieldWrap">
                <Label
                  htmlFor="industrySector"
                  size={LabelSize.Medium}
                  text="What industry sector do you work in?"
                />
                <Select
                  id='industrySector'
                  data-test-id="industrySector"
                  onOptionsChange={(value: any) => changeIndustrySector(value)}
                  size={SelectSize.Large}
                  classNames="selectField"
                  options={industrySectOptions !== undefined ? industrySectOptions : []}
                  defaultValue={industrySector ?? "Select"}
                  status={errors?.showIndustrySectorStatus}
                  disabled={disableIndustrySec ? true : false}
                />
                {errors?.showIndustrySectorMsg && <span className="errorMessage">{errors?.showIndustrySectorMsg}</span>}
              </div>}
            </>
          }

          {describeIndustrySec &&
            <div className="fieldWrap">
              <TextInput
                htmlType="text"
                id="industrySecDescribe"
                clearable
                maxlength={100}
                minlength={0}
                name="industrySecDescribe"
                onChange={(e) => {
                  setindustrySecTxt(e.target.value);
                }}
                value={industrySecTxt}
                defaultValue={industrySecTxt}
                inputWidth={TextInputWidth.fill}
                classNames="inputTextField"
                size={TextInputSize.Large}
                status={errors?.industrySecDesMsg}
              />
              {errors?.industrySecDesMsg && (
                <span className="errorMessage">{errors?.industrySecDesMsg}</span>
              )}
            </div>
          }
          {props?.showFields?.showOrgType &&
            <div className="fieldWrap">
              <Label htmlFor="orgType" size={LabelSize.Medium} text="What type of organization do you work for?" />
              <Select
                id="orgType"
                defaultValue={props?.orgType}
                onOptionsChange={(e: any) => {
                  setOrgType(e?.[0]);
                }}
                options={props?.orgTypeOptions}
                classNames="selectField"
                size={SelectSize.Large}
                status={errors?.showOrgTypeStatus}
              />
              {errors?.showOrgTypeMsg && <span className="errorMessage">{errors?.showOrgTypeMsg}</span>}
            </div>}
          {props?.showFields?.showOrgStructure &&
            <div className="fieldWrap">
              <Label
                htmlFor="orgStructure"
                size={LabelSize.Medium}
                text="What is the organizational structure of your company?"
              />
              <Select
                id="orgStructure"
                defaultValue={props?.orgStructure}
                onOptionsChange={(e: any) => {
                  setOrgStructure(e?.[0]);
                }}
                options={props?.orgStructureOptions}
                classNames="selectField"
                size={SelectSize.Large}
                status={errors?.showOrgStructureStatus}
              />
              {errors?.showOrgStructureMsg && <span className="errorMessage">{errors?.showOrgStructureMsg}</span>}
            </div>}

          {props?.showFields?.showOrgSize &&
            <div className="fieldWrap">
              <Label htmlFor="orgSize" size={LabelSize.Medium} text="What is the size of your organization?" />
              <Select
                id="orgSize"
                defaultValue={props?.orgSize}
                onOptionsChange={(e: any) => {
                  setOrgSize(e?.[0]);
                }}
                options={props?.orgSizeOptions}
                classNames="selectField"
                size={SelectSize.Large}
                status={errors?.showOrgSizeStatus}
              />
              {errors?.showOrgSizeMsg && <span className="errorMessage">{errors?.showOrgSizeMsg}</span>}
            </div>}
          {props?.showFields?.showOrgCycle &&
            <div className="fieldWrap">
              <Label
                htmlFor="orgCycle"
                size={LabelSize.Medium}
                text="What cycle/stage is your organization currently in?"
              />
              <Select
                id='orgCycle'
                defaultValue={props?.orgCycle}
                onOptionsChange={(e: any) => {
                  setOrgCycle(e?.[0]);
                }}
                options={props?.orgCycleOptions}
                classNames="selectField"
                size={SelectSize.Large}
                status={errors?.showOrgCycleStatus}
              />
              {errors?.showOrgCycleMsg && <span className="errorMessage">{errors?.showOrgCycleMsg}</span>}
            </div>}

          {props?.showFields?.showOrgRevenue &&
            <div className="fieldWrap">
              <Label htmlFor="orgRevenue" size={LabelSize.Medium} text="How much revenue does your company generate annually?" />
              <Select
                id='orgRevenue'
                defaultValue={props?.orgRevenue}
                onOptionsChange={(e: any) => {
                  setOrgRevenue(e?.[0]);
                }}
                options={props?.orgRevenueOptions}
                classNames="selectField"
                size={SelectSize.Large}
                status={errors?.showOrgRevenueStatus}
              />
              {errors?.showOrgRevenueMsg && <span className="errorMessage">{errors?.showOrgRevenueMsg}</span>}
            </div>}

          {props?.showFields?.showWorkingEnvironment &&
            <div className="fieldWrap">
              <Label htmlFor="workingEnvironment" size={LabelSize.Medium} text="What type of environment do you work in?" />
              <Select
                id="workingEnvironment"
                defaultValue={props?.workingEnvironment}
                onOptionsChange={(e: any) => {
                  setWorkingEnvironment(e?.[0]);
                }}
                options={props?.workingEnvironmentOptions}
                classNames="selectField"
                size={SelectSize.Large}
                status={errors?.showWorkingEnvironmentStatus}
              />
              {errors?.showWorkingEnvironmentMsg && <span className="errorMessage">{errors?.showWorkingEnvironmentMsg}</span>}
            </div>}

          <div className="fieldWrap-Btn">
            <Icon
              classNames="secondaryButton"
              color="#BA3A19"
              data-test-id="myIconTestId"
              id="myIconId"
              path={IconName.mdiChevronLeft}
              role="presentation"
              rotate={0}
              size="20px"
              title="My icon title."
            />
            <Button
              text="Back"
              variant={ButtonVariant.SystemUI}
              classNames="backButton"
              // style={secondaryButtonStyle}
              onClick={() => backtoRolePage()}
              transparent={true}
            />
            <Button
              text="Continue"
              variant={ButtonVariant.Secondary}
              classNames="secondaryButton"
              style={secondaryButtonStyle}
              onClick={() => {
                saveCompanyData();
              }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />
          </div>
        </>
      }
      {errorMsg && <span className="apiErrorMessage">An error occurred</span>}

    </div>
  );
};

export default Company;