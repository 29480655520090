import React, { createContext, ReactElement, ReactNode, useMemo, useReducer } from 'react';
import Reducer from './reducer';
import { ContextType } from './types';
export function GlobalStore({ children }: { children: ReactNode }): ReactElement {
  const [globalState, dispatch] = useReducer(Reducer, initializeState());

  const globalContextValue = useMemo(
    () => ({
      dispatch, globalState
    }),
    [dispatch, globalState]
  );

  return <globalContext.Provider value={globalContextValue}>{children}</globalContext.Provider>;
}

export const globalContext = createContext({} as ContextType);

export const initialState: any = {
  leaderData: {},
  clientData: {},
  lookUpData: [],
  showLogoutModal: false,
};

function initializeState() {
  return initialState;
}
