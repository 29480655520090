import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation
} from 'react-router-dom';
import '@eightfold.ai/octuple/lib/octuple.css';
import WelcomePage from '../pages/WelcomePage';
import Onboarding from '../pages/onboarding/Onboarding';
import DashBoard from '../pages/DashBoard';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from './config';
import { RequiredAuth } from './RequiredAuth';
import InviteExpired from '../pages/InviteExpired';
import IdleTimer from '../IdleTime';
import { ShowLogout } from '../commons/commonFunctions';

const okatAuth = new OktaAuth(config.oidc);
const AppRouter = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const restoreOriginalUri = async (_okatAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '', window.location.origin), {
      replace: true,
    });
  }

  const titleDictionary: any = {
    '/': 'validation | Heidrick Digital | Welcome',
    '/welcome': 'validation | Heidrick Digital | Welcome',
    '/register-account': 'validation | Heidrick Digital | Register | Account',
    '/register-role': 'validation | Heidrick Digital | Register | Role',
    '/register-company': 'validation | Heidrick Digital | Register | Company',
    '/register-personal': 'validation | Heidrick Digital | Register | Personal',
    '/login': 'validation | Heidrick Digital | Login',
    '/forgot-password': 'validation | Heidrick Digital | Forgot Password',//NOSONAR
    '/invite-expired': 'validation | Heidrick Digital | Invite Expired',
  };

  useEffect(() => {
    let currentPath = location.pathname;
    document.title = titleDictionary[currentPath] || 'Heidrick Digital';
  }, [location]);

  return (
    <Security
      oktaAuth={okatAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route element={<RequiredAuth />}>
          <Route path="*" element={<WelcomePage />} />
          <Route path='/welcome' element={<WelcomePage />} />
          <Route path='/register-account' element={<Onboarding />} />
          <Route path='/register-role' element={<Onboarding />} />
          <Route path='/register-company' element={<Onboarding />} />
          <Route path='/register-personal' element={<Onboarding />} />
          <Route path='/dashboard' element={<DashBoard />} />

        </Route>
        <Route
          path={'/login/callback'}
          element={<LoginCallback />}
        />
        <Route
          path={'/invite-expired'}
          element={<InviteExpired />}
        />
      </Routes>
      <IdleTimer />
      <ShowLogout />
    </Security>
  )
}

export default AppRouter;