import { ActionType } from './types';
import { initialState } from './index';
import { ShowLogout } from '../commons/commonFunctions';

const Reducer = (state: any, action: ActionType): any => {
  switch (action.type) {
    case "SET_LEADER_DATA":
      return {
        ...state,
        leaderData: action.payload,
      };
    case "SET_CLIENT_DATA":
      return {
        ...state,
        clientData: action.payload,
      };
    case "SET_LOOKUP_DATA":
      return {
        ...state,
        lookUpData: action.payload,
      };
       case "SET_SHOW_LOGOUT_MODAL":
      return {
        ...state,
        showLogoutModal: action.payload,
      };
    case "PURGE_STATE":
      return initialState;
    default:
      return state;
  }
};

export default Reducer;
