import React, { useContext, useEffect, useState } from "react";
import AuthHeader from '../commons/AuthHeader';
import AuthFooter from '../commons/AuthFooter';
import { Row, Col, Pill, PillIconAlign, PillSize, PillType, Button, ButtonVariant, Stepper, StepperVariant, StepperLineStyle, StepperSize, StepSize } from "@eightfold.ai/octuple";
import { actionButtonStyle } from "../commons/commonStyles";
import { globalContext } from "../store";
import Api from "../Api";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ReportCard from "../Components/ReportCard";
import Loader from "../commons/Loader";
import { getHeaderLogo, getHeaderImg } from '../commons/commonFunctions';
import { useNavigate } from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone);

const formatDate = (dateString: any) => {
  const formatedDate = dayjs(dateString).format('MMM D, YYYY');
  return formatedDate;
}

const getDaysDifferenceTodaysDate = (dueDate: any, todaysDate: any) => {
  if (todaysDate.getTime() === dueDate.getTime()) {
    return 0;
  }
  const msDiff = dueDate.getTime() - todaysDate.getTime();
  let days = Math.floor(msDiff / (1000 * 60 * 60 * 24));
  return Math.abs(days);
}

const GeneratePill = (props: any) => {
  const assessmentsData = props?.assessmentsData;
  let pillLabel = 'New';
  let pillClass = 'pillStyleBlue';
  let todaysDate = new Date();
  const stripTime = (date: any) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const dueDate = new Date(assessmentsData.dueDate);
  const strippedDueDate = stripTime(dueDate);
  const strippedTodaysDate = stripTime(todaysDate);

  const days = getDaysDifferenceTodaysDate(dueDate, strippedTodaysDate)

  if (strippedDueDate.getTime() < strippedTodaysDate.getTime()) {
    pillLabel = (days === 1) ? `Overdue by ${days} Day` : `Overdue by ${days} Days`;
    pillClass = 'pillStyleRed';
  }

  if (strippedDueDate.getTime() === strippedTodaysDate.getTime()) {
    pillLabel = `Due today`;
    pillClass = 'pillStyleRed';
  }

  if (strippedDueDate.getTime() > new Date(todaysDate.setDate(todaysDate.getDate())).getTime() && strippedDueDate.getTime() < new Date(todaysDate.setDate(todaysDate.getDate() + 5)).getTime()) {
    pillLabel = (days === 1) ? `Due in ${days} Day` : `Due in ${days} Days`;
    pillClass = 'pillStyleRed';
  }

  todaysDate = new Date();
  if (strippedDueDate.getTime() > new Date(todaysDate.setDate(todaysDate.getDate() + 5)).getTime() && assessmentsData.status === 'I') {
    pillLabel = (days === 1) ? `Due in ${days} Day` : `Due in ${days} Days`;
    pillClass = 'pillStyleBlue';
  }

  return (
    <Pill
      alignIcon={PillIconAlign.Start}
      closeButtonProps={{
        ariaLabel: 'Close'
      }}
      configContextProps={{
        noDisabledContext: false,
        noGradientContext: false,
        noSizeContext: false
      }}
      label={pillLabel}
      lineClamp={0}
      onClick={function noRefCheck() { }}
      onClose={function noRefCheck() { }}
      classNames={pillClass}
      size={PillSize.Small}
      type={PillType.default}
    />
  )
};

const openAssessments = (url: string) => {
  window.open(url, '_blank');
}

const GenerateCards = (props: any) => {
  const assessmentsData: any = props?.assessmentsData;
  const isMobile: any = props?.isMobile;
  const assessmentSourceStatus = assessmentsData?.assessmentDetails?.source?.status;
  const assessmentDataStatus = assessmentsData?.status;
  const stepperClass = (assessmentSourceStatus || assessmentDataStatus);
  const assessmentSourceStatusCheck = (assessmentSourceStatus === 'P') ? 1 : 2;
  const assessmentUrl = assessmentsData?.assessmentDetails?.source?.url;
  let btnTxt = '';
  if (assessmentSourceStatus === 'N') {
    btnTxt = 'Begin';
  } else if (assessmentSourceStatus === 'P') {
    btnTxt = 'Continue';
  }

  return (
    <Row classNames="gridLeftRow">
      <Col classNames="gridLeftRowsLeftColumnDetails">
        {assessmentSourceStatus !== 'C' && <GeneratePill assessmentsData={assessmentsData} />}
        <h3>{assessmentsData?.title}</h3>
        {assessmentSourceStatus !== 'C' &&
          <Button
            text={btnTxt}
            variant={ButtonVariant.Secondary}
            classNames="actionButton"
            style={actionButtonStyle}
            onClick={() => openAssessments(assessmentUrl)}
          />
        }
      </Col>
      <Col classNames="gridLeftRowsRightColumnDetails">
        <p className="paragraphStyle" style={{ marginTop: '0px' }}>{assessmentsData?.description}</p>
        <div className={`stepper-container ${stepperClass}`} style={{ width: '100%' }}>
          <Stepper
            activeStepIndex={(assessmentSourceStatus === 'N' || assessmentsData.assignmentStatus === 'NI') ? 0 : assessmentSourceStatusCheck}
            classNames={`myTimeline ${stepperClass}`}
            configContextProps={{
              noGradientContext: false,
              noThemeContext: false
            }}
            data-test-id="myTimelineTestId"
            id="myTimelineId"
            index={(assessmentSourceStatus === 'N' || assessmentDataStatus === 'NI') ? 0 : assessmentSourceStatusCheck}
            layout={isMobile ? 'vertical' : 'horizontal'}
            lineStyle={StepperLineStyle.Solid}
            size={StepperSize.Medium}
            showActiveStepIndex
            steps={[
              {
                complete: (assessmentSourceStatus === 'N'),
                content: 'Assigned',
                index: 1,
                size: StepSize.Small,
              },
              {
                complete: (assessmentSourceStatus === 'P'),
                content: 'In Progress',
                index: 2,
                size: StepSize.Small,
              },
              {
                complete: (assessmentSourceStatus === 'C'),
                content: 'Completed',
                index: 3,
                size: StepSize.Small
              }
            ]}
            theme="grey"
            themeContainerId="my-timeline-theme-container"
            variant={StepperVariant.Timeline}
          />
        </div>
        <p className="dateStyle">
          <span>
            {assessmentSourceStatus === 'C' ?
              `Completed: ${formatDate(assessmentsData?.lastUpdatedTs)}`
              :
              `Due: ${(assessmentsData?.dueDate) ? formatDate(assessmentsData?.dueDate) : ''}`
            }
          </span>
          <span>
            Assigned: {formatDate(assessmentsData?.assessmentDetails?.addedTs)}
          </span>
        </p>
      </Col>
    </Row>
  );
};


const DashBoard = () => {
  const navigate = useNavigate();
  const { globalState, dispatch } = useContext(globalContext);
  const title = globalState?.clientData?.name;
  const [reportsData, setReportsData] = useState<any>([]);
  const [showHideCompletedAssessments, setShowHideCompletedAssessmenst] = useState(false);
  const [assignedAssessments, setAssignedAssessments] = useState<any>([]);
  const [completedAssessArr, setCompletedAssessArr] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [leaderName, setLeaderName] = useState<any>('')
  const showHideCompletedAssess = () => {
    setShowHideCompletedAssessmenst(!showHideCompletedAssessments)
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 450);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    if (Object.keys(globalState?.leaderData).length === 0 || Object.keys(globalState?.lookUpData).length === 0) {
      getLeaderData();
    } else {
      if (!globalState?.leaderData?.isProfileCompleted) {
        navigate('/welcome');
      }
      setLeaderName(globalState?.leaderData?.firstName)
      getLeaderProjects(globalState?.leaderData?.id);
    }
  }, []);

  const getLeaderData = () => {
    let loginToken: any = JSON.parse(
      sessionStorage.getItem("loginToken") ?? "false"
    );

    const payload = {
      email: loginToken?.idToken?.claims?.email.toLowerCase(),
      clientId: globalState?.clientData?.id,
    };

    Api.getLeaderProfile(payload).then((response) => {
      if (response?.success) {
        if (response && response?.response?.data && response?.response?.data?.data.length > 0) {
          const res = response?.response?.data?.data?.[0];
          dispatch({
            type: "SET_LEADER_DATA",
            payload: res,
          });
          if (!res?.isProfileCompleted) {
            navigate('/welcome');
          }
          const leaderProfileId = response?.response?.data?.data[0]?.id;
          setLeaderName(response?.response?.data?.data[0]?.firstName)
          getLeaderProjects(leaderProfileId);
          getLeaderReports(leaderProfileId);
        }
      }
      if (response?.data?.code === 401 && response?.data?.message === 'Unauthorized Access') {
        dispatch({
          type: "SET_SHOW_LOGOUT_MODAL",
          payload: true,
        });
      }
    }).catch((err: any) => {
      if (err?.code === 401 && err?.message === 'Unauthorized Access') {
        dispatch({
          type: "SET_SHOW_LOGOUT_MODAL",
          payload: true,
        });
      }
    });;
  }

  const getLeaderProjects = (leaderProfileId: any) => {
    let assessmentStructureIds: any = [];
    let idsString;
    Api.getLeaderProjects(globalState?.clientData?.id, leaderProfileId)
      .then((response: any) => {
        if (response?.success) {
          const flattenedAssignments = response?.response?.data?.data.flatMap((project: any) =>
            project?.assignments?.flatMap((assignment: any) =>
              assignment?.assessments?.map((assessment: any) => ({
                ...assessment,
                dueDate: project?.closeDate || '',
                ...(assessment?.assignmentStatus === 'Not Invited' && { status: 'NI' })
              }))
            )
          );
          assessmentStructureIds.push(...flattenedAssignments?.map((assessment: any) => {
            if (assessment?.assessmentStructureId) {
              return assessment?.assessmentStructureId
            }
          }));
          idsString = assessmentStructureIds.filter((item: any) => item).join(',');
          Api.getLeaderAssessmentStructureDetails(idsString)
            .then((res: any) => {
              getAssessmentStructureDetails(flattenedAssignments, res?.response?.data?.data, leaderProfileId);
            }).catch((err) => {
              setShowLoader(false);
              if (err?.code === 401 && err?.message === 'Unauthorized Access') {
                dispatch({
                  type: "SET_SHOW_LOGOUT_MODAL",
                  payload: true,
                });
              }
            });
        } else {
          setShowLoader(false);
          if (response?.data?.code === 401 && response?.data?.message === 'Unauthorized Access') {
            dispatch({
              type: "SET_SHOW_LOGOUT_MODAL",
              payload: true,
            });
          }
        }
      })
      .catch((err) => {
        setShowLoader(false);
        if (err?.code === 401 && err?.message === 'Unauthorized Access') {
          dispatch({
            type: "SET_SHOW_LOGOUT_MODAL",
            payload: true,
          });
        }
      });
  }

  const getLeaderReports = (leaderProfileId: any) => {
    Api.getLeaderReports(leaderProfileId)
      .then((response: any) => {
        if (response?.success) {
          if (response && response?.response?.data?.data?.length > 0) {
            const res = response?.response?.data?.data
            const filteredData = res.filter((item: any) => item.isLeaderEnabled === true)
            filteredData.sort((a: any, b: any) => {
              const dateA = new Date(a.reportDate);
              const dateB = new Date(b.reportDate);

              switch (dateA > dateB) {
                case true:
                  return -1;
                case false:
                  return 1;
              }
              return a.name.localeCompare(b.name);
            });
            setReportsData(filteredData)
          }
        }
        setShowLoader(false);
        if (response?.data?.code === 401 && response?.data?.message === 'Unauthorized Access') {
          dispatch({
            type: "SET_SHOW_LOGOUT_MODAL",
            payload: true,
          });
        }
      })
      .catch((err) => {
        setShowLoader(false);
        if (err?.code === 401 && err?.message === 'Unauthorized Access') {
          dispatch({
            type: "SET_SHOW_LOGOUT_MODAL",
            payload: true,
          });
        }
      });
  }

  const getAssessmentStructureDetails = async (flattenedAssignments: any, assessStructureData: any, leaderProfileId: any) => {
    await Api.getLeaderAssessmentDetails(globalState?.clientData?.id, leaderProfileId)
      .then((response: any) => {
        if (response?.success) {
          const assessmentData = response?.response?.data?.data;
          assessmentData.sort((value1: any, value2: any) => value1?.lastUpdatedTs - value2?.lastUpdatedTs);

          assessmentData.map((data: any) => {
            flattenedAssignments?.find((element: any) => {
              if (element?.assessmentId === data?.id) {
                element['assessmentDetails'] = data;
              }
            })
          });

          const updatedData = flattenedAssignments.map((dataItem: any) => {
            let match: any;
            match = assessStructureData?.find((structureItem: any) => structureItem?.id === dataItem?.assessmentStructureId);
            if (match) {
              return { ...dataItem, title: match?.title, description: match?.description };
            }
          });

          const finalUpdatedData = updatedData.map((item: any) => {
            if (item?.closeDate !== '') {
              return { ...item, dueDate: item?.dueDate };
            } else {
              const date = new Date(item?.addedTs);
              date.setFullYear(date.getFullYear() + 1);
              const dueDate = date.toISOString();
              return { ...item, dueDate: dueDate };
            }
          })

          //filter completed assessments
          const filteredCompletedAsses = finalUpdatedData.filter((item: any) => item?.assessmentDetails?.source?.status === 'C');
          const filteredAssignedAsses = finalUpdatedData.filter((item: any) => item?.assessmentDetails?.source?.status === 'N' || item?.assessmentDetails?.source?.status === 'P');
          filteredCompletedAsses.sort((a: any, b: any) => {
            const dateA = new Date(a?.lastUpdatedTs);
            const dateB = new Date(b?.lastUpdatedTs);

            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;

            return a?.title.localeCompare(b?.title);
          });
          setCompletedAssessArr(filteredCompletedAsses);

          filteredAssignedAsses.sort((a: any, b: any) => {
            const dateA = new Date(a?.dueDate);
            const dateB = new Date(b?.dueDate);
            if (dateA < dateB) return -1;
            if (dateA > dateB) return 1;
            return a?.title?.localeCompare(b.title);
          });
          setAssignedAssessments(filteredAssignedAsses);
        }
        setShowLoader(false);
        if (response?.data?.code === 401 && response?.data?.message === 'Unauthorized Access') {
          dispatch({
            type: "SET_SHOW_LOGOUT_MODAL",
            payload: true,
          });
        }
      }).catch((err: any) => {
        setShowLoader(false);
        if (err?.code === 401 && err?.message === 'Unauthorized Access') {
          dispatch({
            type: "SET_SHOW_LOGOUT_MODAL",
            payload: true,
          });
        }
      });
  }

  return (
    <>
      {showLoader && <Loader />}
      <div className="mainWrapper">
        <div className="dashboardPage">
          <AuthHeader title={title} headerLogo={getHeaderLogo(globalState)} setLoader={setShowLoader} />
          <div className="authHeaderBanner" style={{ background: `url(${getHeaderImg(globalState)}) no-repeat`, backgroundPosition: 'right', backgroundColor: '#F6F7F8' }}>
            <div className="authHeaderBannerContent">
              <p className="authHeaderBannerWelcomeContent">Welcome,</p>
              <h2 className="header2Style">{leaderName}</h2>
            </div>
          </div>
          <div className="middleWrapper">
            <Row classNames="gridWrapper">
              <Col classNames="gridLeftColumn">
                <h2>Assessments</h2>
                <>
                  {(assignedAssessments.length) > 0 ?
                    assignedAssessments.map((item: any) => (
                      <GenerateCards key={item?.id} assessmentsData={item} isMobile={isMobile} />
                    ))
                    :
                    <Row classNames="emptyDataState">
                      <Col classNames="emptyStateLogo">
                        <img className="classEllipse" src={`./../assets/img/Ellipse3Assessment.svg`} alt="" />
                        <img className="classGroup1" src={`./../assets/img/Group1.svg`} alt="" />
                        <img className="classGroup2" src={`./../assets/img/Group2.svg`} alt="" />
                        <h3>You don't have any assessments assigned yet</h3>
                      </Col>
                    </Row>
                  }

                  {completedAssessArr.length > 0 &&
                    <>
                      <div className="completedAssessList">
                        <img
                          className={showHideCompletedAssessments ? "showCompletedButtonCls" : ''}
                          alt="plus minus icon"
                          src={showHideCompletedAssessments ? `./../assets/img/HideCompletedAssess.png` : `./../assets/img/ShowCompletedAssess.png`}
                        />
                        <Button
                          text={showHideCompletedAssessments ? "Hide Completed Assessments" : "Show Completed Assessments"}
                          variant={ButtonVariant.SystemUI}
                          classNames="backButton"
                          onClick={showHideCompletedAssess}
                          transparent={true}
                        />
                      </div>

                      {showHideCompletedAssessments ?
                        <div className="showCompleted">
                          {completedAssessArr.map((item: any) => (
                            <GenerateCards key={item?.id} assessmentsData={item} isMobile={isMobile} />
                          ))}

                        </div> :
                        <></>
                      }
                    </>
                  }
                </>
              </Col>
              <ReportCard reportsData={reportsData} formatDate={formatDate} />
            </Row>
          </div>
          <AuthFooter />
        </div>
      </div>
    </>
  );
};

export default DashBoard;
