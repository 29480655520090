import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonVariant, IconName, ButtonIconAlign } from "@eightfold.ai/octuple";
import Header from "../commons/Header";
import { secondaryButtonStyle } from "../commons/commonStyles";
import Footer from "../commons/Footer";
import Api from "../Api";
import { globalContext } from "../store";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {filterFields, getOnboardingImg, getHeaderLogo, checkUnauthorized, checkAuthErr} from  '../commons/commonFunctions';
import {clientInfo} from '../commons/ClientDynamicInfo';
const WelcomePage = () => {
  const navigate = useNavigate();
  const { globalState, dispatch } = useContext(globalContext);
  const [welcomeText, setWelcomeText] =  useState<any>("")
  const [showLoader, setShowLoader] = useState(false);
  const title = globalState?.clientData?.name;

  useEffect(()=>{
    if (clientInfo.hasOwnProperty(globalState?.clientData?.name)) {
      setWelcomeText(clientInfo[globalState?.clientData?.name].welcomeText.split('\n'))
    } else {
      setWelcomeText(clientInfo['*'].welcomeText.split('\n'));
    }
  },[])

  let loginToken: any = JSON.parse(
    sessionStorage.getItem("loginToken") ?? "false"
  );

  let clientId: any = JSON.parse(
    sessionStorage.getItem("clientId") ?? "false"
  );

  const payload = {
    email: loginToken?.idToken?.claims?.email,
    clientId: clientId,
  };

  const areFieldsPresent = (obj: any, fields: any) => {
    return fields.every((field: any) => field.split('.').reduce((o: any, k: any) => (o || {})[k], obj));
  }

  let accountNotCompleted = true;
  let roleNotCompleted = true;
  let companyNotCompleted = true;
  let personalNotCompleted = true;
  
  const accountFields = ['firstName', 'lastName', 'email','applications.leaderPortal.isPrivacyPolicyAccepted'];
  
  let personalFields = [
    'demographicSurveyData.gender',
    'demographicSurveyData.countryOfResidence',
    'demographicSurveyData.ethnicity',
    'demographicSurveyData.age',
    'demographicSurveyData.education',    
  ];

  const filteredRoleFields = filterFields('roleFields', globalState);
  const filteredCompanyFields = filterFields('companyFields', globalState);
  
  const redirectionPaths =(res:any) =>{
        
    if (res?.demographicSurveyData?.countryOfResidence === 'United States of America (the)' || res?.demographicSurveyData?.countryOfResidence === 'United Kingdom of Great Britain and Northern Ireland (the)') {
      personalFields.push('demographicSurveyData.lgbtq');
      personalFields.push('demographicSurveyData.diversityNonSpecific');
    }

    if (res?.demographicSurveyData?.countryOfResidence === 'United States of America (the)') {
      personalFields.push('demographicSurveyData.veteranStatus');
    }

    accountNotCompleted = !areFieldsPresent(res, accountFields);
    roleNotCompleted = !areFieldsPresent(res, filteredRoleFields);
    companyNotCompleted = !areFieldsPresent(res, filteredCompanyFields);
    personalNotCompleted = !areFieldsPresent(res, personalFields);

    const navigationPaths = [
      { condition: res.isProfileCompleted, path: "/dashboard" },
      { condition: accountNotCompleted, path: "/register-account" },
      { condition: roleNotCompleted, path: "/register-role" },
      { condition: companyNotCompleted, path: "/register-company" },
      { condition: personalNotCompleted, path: "/register-personal" }
    ];

    for (let row of navigationPaths ) {
      if (row.condition) {
        navigate(row?.path);
        break;
      }
    } 
  }

  const onClickContinue = () => {
    setShowLoader(true);
    Api.getLeaderProfile(payload)
      .then((response: any) => {     
        if (response?.success) {
          const res = response?.response?.data?.data?.[0];
          dispatch({
            type: "SET_LEADER_DATA",
            payload: res,
          });
          redirectionPaths(res);       
        } else {
          setShowLoader(false);         
          checkUnauthorized(response,dispatch);  
        }
      })
      .catch((err) => {
        setShowLoader(false);
        checkAuthErr(err,dispatch)
      });
  };

  const renderWelcomeText = (text:any) => {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    const emails = text.match(emailRegex);
    return text.split(emailRegex).map((part:any, index:any, array:any) => (
      <>
        {part}
        {index < array.length - 1 && (
          <Link to={`mailto:${emails[index]}`} key={emails[index]} className="emailLink">
            {emails[index]}
          </Link>
        )}
      </>
    ));
  };

  return (
    <div className="mainWrapper">
      <div className="imgWrapper" style={{ background:`url(${getOnboardingImg(globalState)}) no-repeat`  }}>
        &nbsp;
      </div>
      <div className="rightWrapper">
        <Header title={title} headerLogo={getHeaderLogo(globalState)}/>
        <div className="contentWrapper">
          <h1 className="titleStyle">Welcome to the Leader Portal</h1>
          <div className="middleContent">
            {welcomeText && welcomeText.map((text:any) => (
              <p className="welcomeContent" key={text} >{renderWelcomeText(text)}</p>
            ))}

            <Button
              text="Continue"
              variant={ButtonVariant.Secondary}
              classNames="secondaryButton"
              style={secondaryButtonStyle}
              onClick={() => {
                onClickContinue();
              }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />
          </div>
        </div>
        <Footer />
      </div>
    </div >
  );
};

export default WelcomePage;
