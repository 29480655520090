import React, { useContext, useState } from 'react';
import { Row, Col, Button, ButtonVariant, IconName, ButtonIconAlign } from "@eightfold.ai/octuple";
import { secondaryButtonStyle } from '../commons/commonStyles';
import Api from '../Api';
import { globalContext } from '../store';

const ReportCard = (props: any) => {
  const [showLoader, setShowLoader] = useState(false);
  const { globalState, dispatch } = useContext(globalContext);

  const gotoSupport = () => {
    window.open(`https://heidricksupport.zendesk.com/hc/en-us`, "_blank", "noopener,noreferrer");
  }

  const downloadReport = (attachmentId: any, reportName: string) => {
    setShowLoader(true);
    console.log(attachmentId);

    Api.downloadLeaderReports(attachmentId, reportName)
      .then((res) => {
        if (res.success) setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        if (err?.code === 401 && err?.message === 'Unauthorized Access') {
          dispatch({
            type: "SET_SHOW_LOGOUT_MODAL",
            payload: true,
          });
        }
      });
  }

  return (
    <Col classNames="gridRightColumn">
      <Row classNames="gridRightRow">
        <Col
          classNames="gridRightRowsColumnDetails"
          flex="1 1 200px"
        >
          <h2>Reports</h2>
          {props?.reportsData && props?.reportsData?.length ?

            <>{
              props?.reportsData.map((item: any) => {
                return <>
                  <Row classNames="gridRightChildRow">
                    <Col classNames="gridRightChildDetails">
                      <h3>{item.reportName}</h3>
                      <p className="paragraphStyle">Generated: {props?.formatDate(item.reportDate)}</p>
                    </Col>
                    <Col classNames="actionItem">
                      <Button
                        text="Download"
                        variant={ButtonVariant.Secondary}
                        classNames="secondaryButton"
                        style={secondaryButtonStyle}
                        onClick={() => {
                          downloadReport(item.id, item.reportName);
                        }}
                        iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
                        alignIcon={ButtonIconAlign.Right}
                        disabled={showLoader}
                      />
                    </Col>
                  </Row>
                </>
              })
            }

            </>
            :
            <Row classNames="emptyReportsState">
              <Col classNames="emptyStateLogo">
                <img className="classEllipse" src={`./../assets/img/Ellipse3.svg`} alt="" />
                <img className="classGroup1" src={`./../assets/img/Group1.svg`} alt="" />
                <img className="classGroup2" src={`./../assets/img/Group2.svg`} alt="" />
                <h3>You don't have any reports yet</h3>
              </Col>
            </Row>}
        </Col>
      </Row>

      <Row classNames="gridRightCard">
        <Col classNames="gridCardDetails">
          <h2 className="header2Style">Need Help?</h2>
          <p className="paragraphStyle">For questions or general support <a className="paragraphStyle" href="javascript:void(0)" onClick={() => gotoSupport()} >click here</a></p>
        </Col>
      </Row>
    </Col>

  )
}

export default ReportCard;
