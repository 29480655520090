import {
  Button,
  ButtonVariant,
  Select,
  SelectSize,
  Label,
  LabelSize,
  Icon,
  IconName,
  Tooltip,
  ButtonIconAlign,
  TextInput,
  TextInputWidth,
  TextInputSize,
} from "@eightfold.ai/octuple";
import React, { useState, useEffect, useContext } from "react";
import { secondaryButtonStyle } from "../../commons/commonStyles";
import dayjs from 'dayjs';
import { validateFields, validationOptions } from '../../ValidationSchema';
import { useNavigate } from "react-router";
import { globalContext } from "../../store";
import { updateDemographicData, checkSubDropDownValues, validateDynamicForm } from '../../commons/commonFunctions'
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);


const Personal = (props: any) => {
  const navigate = useNavigate();
  const { globalState, dispatch } = useContext(globalContext);

  const [gender, setGender] = useState('');
  const [genderIdentity, setGenderIdentity] = useState('');
  const [genderIdentityTxt, setGenderIdentityTxt] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [lgbtq, setLgbtq] = useState('');
  const [lgbtqTxt, setLgbtqTxt] = useState('');
  const [diversityNonSpecific, setDiversityNonSpecific] = useState('');
  const [veteranStatus, setVeteranStatus] = useState('');
  const [education, setEducation] = useState('');
  const [countryOfResidence, setCountryOfResidence] = useState('');
  const [age, setAge] = useState<any>('');

  const [errors, setErrors] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState(false)
  const toooltipText = 'Refers to individuals who identify as diverse but either do not fall into one of the above categories (could include but is not limited to veteran status or disability), or do not wish to share further information with the firm as to how they are diverse.'
  const [showLoader, setShowLoader] = useState(false);
  const [ethnicityOptions, setEthnicityOptions] = useState<any>([]);

  const [showEthnicity, setShowEthnicity] = useState(false);
  const [showGenderIdentity, setShowGenderIdentity] = useState(false);
  const [describeGenderIdentity, setDescribeGenderIdentity] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [showLGBTQ, setShowLGBTQ] = useState(false);
  const [describeLGBTQ, setDescribeLGBTQ] = useState(false);
  const [showDiversity, setShowDiversity] = useState(false);
  const [showVeteran, setShowVeteran] = useState(false);
  const countryArrEthnicity = ['United States of America (the)', 'Canada', 'Australia', 'United Kingdom of Great Britain and Northern Ireland (the)'];
  const countryArrUSUK = ['United States of America (the)', 'United Kingdom of Great Britain and Northern Ireland (the)'];
  const countryArrFRBL = ['France', 'Belgium'];
  const selfDescribe = 'Prefer to self-describe';

  useEffect(() => {
    if (props) {
      if (props?.genderIdentity?.includes('~')) {
        setGenderIdentity(selfDescribe);
        setGenderIdentityTxt(props?.genderIdentity.replace('OTH~', ''));
      } else {
        setGenderIdentity(props?.genderIdentity);
      }

      if (props?.lgbtq?.includes('~')) {
        setLgbtq(selfDescribe);
        setLgbtqTxt(props?.lgbtq.replace('OTH~', ''));
      } else {
        setLgbtq(props?.lgbtq);
      }
      setGender(props?.gender);
      setEthnicity(props?.ethnicity);
      setDiversityNonSpecific(props?.diversityNonSpecific);
      setVeteranStatus(props?.veteranStatus);
      setEducation(props?.education);
      setCountryOfResidence(props?.countryOfResidence);
      setAge(props?.age)
    }
  }, [props]);

  const changeCountry = (e: any) => {
    setCountryOfResidence(e?.[0]);
    const ethnicityOptions = checkSubDropDownValues(e?.[0], 'Ethnicity', globalState?.lookUpData);
    if (e?.[0] && ethnicityOptions && ethnicityOptions?.length > 0) {
      setEthnicityOptions(ethnicityOptions);
    }
    showFieldsSpecificToCountry(e?.[0]);
    clearFieldsSpecificToCountry(e?.[0]);
  }

  const changeGenderIdentity = (e: any) => {
    setGenderIdentity(e?.[0]);
    if (e?.[0] === selfDescribe) {
      setDescribeGenderIdentity(true);
    } else {
      setDescribeGenderIdentity(false);
    }
  }

  const changeLGBTQ = (e: any) => {
    setLgbtq(e?.[0]);
    if (e?.[0] === selfDescribe) {
      setDescribeLGBTQ(true);
    } else {
      setDescribeLGBTQ(false);
    }
  }

  const showFieldsSpecificToCountry = (countryName: string) => {
    if (countryArrEthnicity.includes(countryName) && typeof (countryName) !== 'undefined') {
      setShowEthnicity(true);
      setShowGender(false);
      setShowGenderIdentity(true);
    } else {
      setShowGender(true);
      setShowGenderIdentity(false);
      setShowEthnicity(false);
      setDescribeGenderIdentity(false);
      setDescribeLGBTQ(false);
    }

    if (countryArrUSUK.includes(countryName) && typeof (countryName) !== 'undefined') {
      setShowLGBTQ(true);
    } else {
      setShowLGBTQ(false);
    }

    if (!countryArrFRBL.includes(countryName) && typeof (countryName) !== 'undefined') {
      setShowDiversity(true);
    } else {
      setShowDiversity(false);
    }

    if (countryName === 'United States of America (the)') {
      setShowVeteran(true);
    } else {
      setShowVeteran(false);
    }
  }

  const clearFieldsSpecificToCountry = (countryName: string) => {
    if (!countryArrEthnicity.includes(countryName) && typeof (countryName) !== 'undefined') {
      setGenderIdentity('');
      setGenderIdentityTxt('');
      setLgbtq('');
      setLgbtqTxt('');
      setEthnicity('');
      setVeteranStatus('');
    } else if (!countryArrFRBL.includes(countryName) && typeof (countryName) !== 'undefined') {
      setGender('');
    }

    if (countryArrFRBL.includes(countryName) && typeof (countryName) !== 'undefined') {
      setDiversityNonSpecific('');
    }
  }
  
const validationForLgbtq = () => {
  let lgbtqMsg;
  let diversityNonSpecificMsg;
  let lgbtqDesMsg;

  if (countryArrUSUK.includes(countryOfResidence)) {
    if(props?.showFields?.showLgbtq ){
      lgbtqMsg = validateFields.validate({ dropDown: lgbtq || '' }, validationOptions)?.error?.details[0]?.message;
    }
    if (lgbtq === selfDescribe) {
      lgbtqDesMsg = validateFields.validate({ textField: lgbtqTxt || '' }, validationOptions)?.error?.details[0]?.message;
    }
  }

  if (!countryArrFRBL.includes(countryOfResidence) && props?.showFields?.showDiversityNonSpecific) {
    diversityNonSpecificMsg = validateFields.validate({ dropDown: diversityNonSpecific || '' }, validationOptions)?.error?.details[0]?.message;
  }
  return { lgbtqMsg,diversityNonSpecificMsg,lgbtqDesMsg }
}
 
const veteranMsgCheck = () => {
  let veteranMsg;
  if (
    countryOfResidence === "United States of America (the)" &&
    props?.showFields?.showVeteranStatus
  ) {
    veteranMsg = validateFields.validate(
      { dropDown: veteranStatus || "" },
      validationOptions
    )?.error?.details[0]?.message;
  }
  return veteranMsg;
};

  const validateOptions = () => {
    let ethnicityMsg;
    let genderIdentityMsg;
    let genderMsg;
    let genderIdentityDesMsg;
    

    const  { lgbtqMsg, diversityNonSpecificMsg  ,lgbtqDesMsg} = validationForLgbtq();

    if (countryArrEthnicity.includes(countryOfResidence)) {
      if(props?.showFields?.showEthnicity){
        ethnicityMsg = validateFields.validate({ dropDown: ethnicity || '' }, validationOptions)?.error?.details[0]?.message;
      }
      if(props?.showFields?.showGenderIdentity){
        genderIdentityMsg = validateFields.validate({ dropDown: genderIdentity || '' }, validationOptions)?.error?.details[0]?.message;
      }
       if (genderIdentity === selfDescribe) {
        genderIdentityDesMsg = validateFields.validate({ textField: genderIdentityTxt || '' }, validationOptions)?.error?.details[0]?.message;
      }
    } else {
      if(props?.showFields?.showGender){
        genderMsg = validateFields.validate({ dropDown: gender || '' }, validationOptions)?.error?.details[0]?.message;
      }
    }
     const veteranMsg = veteranMsgCheck()
     return { lgbtqMsg ,veteranMsg,diversityNonSpecificMsg,ethnicityMsg,genderIdentityMsg,genderMsg ,genderIdentityDesMsg,lgbtqDesMsg }
  };

    const validateFormData = () => {
  
      const fieldConfigs = [
        { name: 'showCountryOfResidence', value: countryOfResidence, type: 'dropDown' },
        { name: 'showAge', value: age, type: 'dropDown' },
        { name: 'showEducation', value: education, type: 'dropDown' },
      ]; 
      
      const filteredFieldConfigs = fieldConfigs?.filter(field => props?.showFields?.[field.name] === true);  
     
      const customValidations = {
        showLgbtqMsg: () => validateOptions()?.lgbtqMsg,
        showVeteranMsg: () => validateOptions()?.veteranMsg,
        showDiversityNonSpecificMsg: () => validateOptions()?.diversityNonSpecificMsg,
        showEthnicityMsg: () => validateOptions()?.ethnicityMsg,
        showGenderIdentityMsg: () => validateOptions()?.genderIdentityMsg,
        showGenderMsg: () => validateOptions()?.genderMsg,
        showGenderIdentityDesMsg: () => validateOptions()?.genderIdentityDesMsg,
        showLgbtqDesMsg: () => validateOptions()?.lgbtqDesMsg,
      };
      
      const isFormInvalid = validateDynamicForm(filteredFieldConfigs,customValidations,  setErrors);
      return isFormInvalid
      };

  const savePersonalData = () => {
    if (validateFormData()) return;
    const payload = {
      isProfileCompleted: true,
      demographicSurveyData: {
        gender: gender,
        genderIdentity: (genderIdentity === selfDescribe) ? `OTH~${genderIdentityTxt}` : genderIdentity,
        ethnicity: ethnicity,
        education: education,
        countryOfResidence: countryOfResidence,
        lgbtq: (lgbtq === selfDescribe) ? `OTH~${lgbtqTxt}` : lgbtq,
        diversityNonSpecific: diversityNonSpecific,
        veteranStatus: veteranStatus,
        age: age,
      }
    };

    const updatedDataForContext: any = {
      demographicSurveyData: {
        ...globalState?.leaderData?.demographicSurveyData,
        gender: gender,
        genderIdentity: (genderIdentity === selfDescribe) ? `OTH~${genderIdentityTxt}` : genderIdentity,
        ethnicity: ethnicity,
        education: education,
        countryOfResidence: countryOfResidence,
        lgbtq: (lgbtq === selfDescribe) ? `OTH~${lgbtqTxt}` : lgbtq,
        diversityNonSpecific: diversityNonSpecific,
        veteranStatus: veteranStatus,
        age: age,
      }
    };

    const newContextData = { ...globalState?.leaderData, ...updatedDataForContext, isProfileCompleted: true, };
    const redirectUrl = '/dashboard';
    updateDemographicData({ payload, leaderProfileId: props.leaderProfileId, redirectUrl, setErrorMsg, newContextData, dispatch, navigate, setShowLoader });
  };

  const backtoCompanyPage = () => {
    navigate('/register-company');
  }

  return (
    <div className="accountWrapper">
      <h1>Personal</h1>
      <h2>Personal Details</h2>

      {props?.countryOfResidenceOptions?.length > 0 &&
        <>
        { props?.showFields?.showCountryOfResidence &&
           
          <div className="fieldWrap">
            <Label
              htmlFor=""
              size={LabelSize.Medium}
              text="What is your country of residence?"
            />
            <Select
              onOptionsChange={(e: any) => {
                changeCountry(e)
              }}
              filterable
              options={props?.countryOfResidenceOptions}
              classNames="selectField"
              size={SelectSize.Large}
              defaultValue={props?.countryOfResidence}
              status={errors?.showCountryOfResidenceStatus}
            />
            {errors?.showCountryOfResidenceMsg && (
              <span className="errorMessage">{errors?.showCountryOfResidenceMsg}</span>
            )}
          </div> }

          {(ethnicityOptions?.length > 0 && showEthnicity && props?.showFields?.showEthnicity)  &&
            <div className="fieldWrap">
              <Label htmlFor="" size={LabelSize.Medium} text="What is your ethnicity?" />
              <Select
                onOptionsChange={(e: any) => {
                  setEthnicity(e?.[0]);
                }}
                options={ethnicityOptions}
                classNames="selectField"
                size={SelectSize.Large}
                defaultValue={props?.ethnicity}
                status={errors?.showEthnicityStatus}
              />
              {errors?.showEthnicityMsg && (
                <span className="errorMessage">{errors?.showEthnicityMsg}</span>
              )}
            </div>
          }
          {(showGenderIdentity && props?.showFields?.showGenderIdentity) &&
            <div className="fieldWrap">
              <Label htmlFor="" size={LabelSize.Medium} text="What is your gender identity?" />
              <Select
                onOptionsChange={(e: any) => {
                  changeGenderIdentity(e);
                }}
                options={props?.genderIdentityOptions}
                classNames="selectField"
                size={SelectSize.Large}
                defaultValue={genderIdentity}
                status={errors?.showGenderIdentityStatus}
              />
              {errors?.showGenderIdentityMsg && (
                <span className="errorMessage">{errors?.showGenderIdentityMsg}</span>
              )}
            </div>
          }

          {describeGenderIdentity &&
            <div className="fieldWrap">
              <TextInput
                htmlType="text"
                id="genderIdentityDescribe"
                clearable
                maxlength={100}
                minlength={0}
                name="genderIdentityDescribe"
                onChange={(e) => {
                  setGenderIdentityTxt(e.target.value);
                }}
                value={genderIdentityTxt}
                inputWidth={TextInputWidth.fill}
                classNames="inputTextField"
                size={TextInputSize.Large}
                status={errors?.showGenderIdentityDesMsg ? 'error' : 'validating'}
              />
              {errors?.showGenderIdentityDesMsg && (
                <span className="errorMessage">{errors?.showGenderIdentityDesMsg}</span>
              )}
            </div>
          }

          {( showGender && props?.showFields?.showGender ) &&
            <div className="fieldWrap">
              <Label htmlFor="" size={LabelSize.Medium} text="What is your gender?" />
              <Select
                onOptionsChange={(e: any) => {
                  setGender(e?.[0]);
                }}
                options={props?.genderOptions}
                classNames="selectField"
                size={SelectSize.Large}
                defaultValue={props?.gender}
                status={errors?.showGenderStatus}
              />
              {errors?.showGenderMsg && (
                <span className="errorMessage">{errors?.showGenderMsg}</span>
              )}
            </div>
          }

          {/* LGBTQ */}
          {(showLGBTQ && props?.showFields?.showLgbtq ) &&
            <div className="fieldWrap">
              <Label htmlFor="" size={LabelSize.Medium} text="How do you identify?" />
              <Select
                onOptionsChange={(e: any) => {
                  changeLGBTQ(e);
                }}
                options={props?.lgbtqOptions}
                classNames="selectField"
                size={SelectSize.Large}
                defaultValue={lgbtq}
                status={errors?.showLgbtqStatus}
              />
              {errors?.showLgbtqMsg && (
                <span className="errorMessage">{errors?.showLgbtqMsg}</span>
              )}
            </div>
          }

          {describeLGBTQ &&
            <div className="fieldWrap">
              <TextInput
                htmlType="text"
                id="LGBTQDescribe"
                clearable
                maxlength={100}
                minlength={0}
                name="LGBTQDescribe"
                onChange={(e) => {
                  setLgbtqTxt(e.target.value);
                }}
                value={lgbtqTxt}
                defaultValue={lgbtqTxt}
                inputWidth={TextInputWidth.fill}
                classNames="inputTextField"
                size={TextInputSize.Large}
                status={errors?.showLgbtqDesMsg ? 'error' : 'validating'}
              />
              {errors?.showLgbtqDesMsg && (
                <span className="errorMessage">{errors?.showLgbtqDesMsg}</span>
              )}
            </div>
          }

          {/* //Diversity */}
          {(showDiversity && props?.showFields?.showDiversityNonSpecific ) &&
            <div className="fieldWrap">
              <div className="tooltipText">
                <Label
                  htmlFor=""
                  size={LabelSize.Medium}
                  text="Do you identify as being a part of any underrepresented group?"
                />
                <Tooltip
                  animate
                  classNames="my-tooltip-class"
                  content={toooltipText}
                  hideAfter={200}
                  id="myTooltipId"
                  offset={8}
                  openDelay={0}
                  placement="bottom"
                  positionStrategy="absolute"
                  tabIndex={-1}
                  trigger="hover"
                  visibleArrow
                >
                  <img src={'../assets/img/materialIcon.svg'} alt="" />
                </Tooltip>
              </div>
              <Select
                onOptionsChange={(e: any) => {
                  setDiversityNonSpecific(e?.[0]);
                }}
                options={props?.diversityNonSpecificOptions}
                classNames="selectField"
                size={SelectSize.Large}
                defaultValue={props?.diversityNonSpecific}
                status={errors?.showDiversityNonSpecificStatus}
              />
              {errors?.showDiversityNonSpecificMsg && (
                <span className="errorMessage">
                  {errors?.showDiversityNonSpecificMsg}
                </span>
              )}
            </div>
          }

          {/* Veteran Status */}
          {(showVeteran &&  props?.showFields?.showVeteranStatus )&&
            <div className="fieldWrap">
              <Label htmlFor="" size={LabelSize.Medium} text="Did you serve on active duty in the U.S. Military?" />
              <Select
                onOptionsChange={(e: any) => {
                  setVeteranStatus(e?.[0]);
                }}
                options={props?.veteranStatusOptions}
                classNames="selectField"
                size={SelectSize.Large}
                defaultValue={props?.veteranStatus}
                status={errors?.showVeteranStatus}
              />
              {errors?.showVeteranMsg && (
                <span className="errorMessage">{errors?.showVeteranMsg}</span>
              )}
            </div>
          }

         { props?.showFields?.showAge &&
          <div className="fieldWrap">
            <Label htmlFor="" size={LabelSize.Medium} text="What is your Age?" />
            <Select
              onOptionsChange={(e: any) => {
                setAge(e?.[0]);
              }}
              options={props?.ageOptions}
              classNames="selectField"
              size={SelectSize.Large}
              defaultValue={props?.age}
              status={errors?.showAgeStatus}
            />
            {errors?.showAgeMsg && (
              <span className="errorMessage">{errors?.showAgeMsg}</span>
            )}
          </div> }


          { props?.showFields?.showEducation &&
          <div className="fieldWrap">
            <Label htmlFor="" size={LabelSize.Medium} text="What is the highest level of education you have completed?" />
            <Select
              onOptionsChange={(e: any) => {
                setEducation(e?.[0]);
              }}
              options={props?.educationOptions}
              classNames="selectField"
              size={SelectSize.Large}
              defaultValue={props?.education}
              status={errors?.showEducationStatus}
            />
            {errors?.showEducationMsg && (
              <span className="errorMessage">{errors?.showEducationMsg}</span>
            )}
          </div>}

          <div className="fieldWrap-Btn">
            <Icon
              classNames="secondaryButton"
              color="#BA3A19"
              data-test-id="myIconTestId"
              description="My icon description."
              id="myIconId"
              path={IconName.mdiChevronLeft}
              role="presentation"
              rotate={0}
              size="20px"
              title="My icon title."
            />
            <Button
              text="Back"
              variant={ButtonVariant.SystemUI}
              classNames="backButton"
              onClick={backtoCompanyPage}
              transparent={true}
            />
            <Button
              text="Submit"
              variant={ButtonVariant.Secondary}
              classNames="secondaryButton"
              style={secondaryButtonStyle}
              onClick={() => {
                savePersonalData();
              }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />
          </div>
        </>
      }
      {errorMsg && <span className="apiErrorMessage">An error occurred</span>}
    </div >
  );
};

export default Personal;
