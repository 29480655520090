import { Button, ButtonIconAlign, ButtonVariant, Modal, ModalSize } from '@eightfold.ai/octuple';
import Api from '../Api';
import { validateFields, validationOptions } from '../ValidationSchema';
import React, { useContext, useState } from 'react';
import { globalContext } from '../store';
import { useOktaAuth } from '@okta/okta-react';
import {clientInfo} from '../commons/ClientDynamicInfo';

const allFields: any = {
  'roleFields':
    [
      'demographicSurveyData.jobTitle',
      'demographicSurveyData.jobLevel',
      'demographicSurveyData.hireDate',
      'demographicSurveyData.jobFunction',
      'demographicSurveyData.jobSubFunction',
      'demographicSurveyData.yearsAtJobLevel',
      'demographicSurveyData.yearsInLeadershipRole',
      'demographicSurveyData.directReports',
      'demographicSurveyData.teamSize'
    ],
  'companyFields': [
    'demographicSurveyData.industry',
    'demographicSurveyData.industrySector',
    'demographicSurveyData.orgType',
    'demographicSurveyData.orgStructure',
    'demographicSurveyData.orgSize',
    'demographicSurveyData.orgCycle',
    'demographicSurveyData.orgRevenue',
    'demographicSurveyData.workingEnvironment'
  ],
   'personalFields': [
    'demographicSurveyData.countryOfResidence',
    'demographicSurveyData.age',
    'demographicSurveyData.education',
  ]
};


export const updateDemographicData = ({ payload, leaderProfileId, redirectUrl, setErrorMsg, newContextData, dispatch, navigate, setShowLoader }: any) => {
  setShowLoader(true);
  return Api.updateLeaderProfile(payload, leaderProfileId)
    .then((res: any) => {
      if (res && res.success) {
        dispatch({
          type: "SET_LEADER_DATA",
          payload: newContextData,
        });
        navigate(redirectUrl);
      } else if (res?.data?.code === 401 && res?.data?.message === 'Unauthorized Access') {
        setShowLoader(false);
        dispatch({
          type: "SET_SHOW_LOGOUT_MODAL",
          payload: true,
        });
      } else {
        setShowLoader(false);
        setErrorMsg(true);
        setTimeout(() => {
          setErrorMsg(false);
        }, 5000);
      }
    })
    .catch((err: any) => {
      console.log(err);
      setShowLoader(false);
      if (err?.code === 401 && err?.message === 'Unauthorized Access') {
        dispatch({
          type: "SET_SHOW_LOGOUT_MODAL",
          payload: true,
        });
      }
    });
}

export const checkDropDownValues = (name: any, lookUpData: any) => {
  let dropDownData: any = []
  lookUpData?.map((item: any) => {
    if (item.dropDownName == name) {
      dropDownData = item.dropDownValues
    }
  })
  return dropDownData
}

export const checkSubDropDownValues = (
  selectedValue: any,
  name: any,
  lookUpData: any
) => {
  let dropDownData: any = [];
  lookUpData?.map((item: any) => {
    if (item.dropDownName == name) {
      item.dropDownValues.map((i: any) => {
        if (i.value === selectedValue) {
          dropDownData = i.subList;
        }
      });
    }
  });
  return dropDownData;
};

export const footerValues = () => {

  const originHost = window.location.origin;

  const onClickFooterItems = (url: any) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return [
    {
      url: "Privacy Notice",
      onClick: () => {
        onClickFooterItems(`${originHost}/assets/pdf/privacy-notice.pdf`);
      },
    },
    {
      url: "Terms of Use",
      onClick: () => {
        onClickFooterItems(`${originHost}/assets/pdf/terms-of-use.pdf`);
      },
    },
    {
      url: "Contact",
      onClick: () => {
        onClickFooterItems(`https://heidricksupport.zendesk.com/hc/en-us`);
      },
    },
  ];
}

const chkForError = (condition: any) => {
  if (condition) {
    return 'error';
  } else {
    return 'validating';
  }
}

export const validateDynamicForm = (fieldConfigs: any, customValidations: {}, setErrors: any) => {
  const errors: any = {};

  fieldConfigs.forEach(({ name, value, type }: any) => {
    const message = validateFields.validate({ [type]: value || '' }, validationOptions)?.error?.details[0]?.message;
    if (message) {
      errors[`${name}Msg`] = message;
      errors[`${name}Status`] = chkForError(message);
    }
  });

  if (customValidations && Object.keys(customValidations).length > 0) {
    Object.entries(customValidations).forEach(([key, validationFn]: any) => {
      const customMessage = validationFn();
      if (customMessage) {
        errors[key] = customMessage;
        errors[`${key.replace('Msg', 'Status')}`] = chkForError(customMessage);
      }
    });
  }

  if (Object.keys(errors).length > 0) {
    setErrors(errors);
    return true;
  } else {
    setErrors({});
    return false;
  }
};

export const filterFields = (keyName: string, globalState: any): string[] => {
  let counter = 0;

  return allFields[keyName].reduce((acc: string[], field: string) => {
    const key = field.split('.').pop();
    if (globalState?.clientData?.demographicSurveyData && counter > 0) {
      if (key && globalState?.clientData?.demographicSurveyData?.[key]) {
        acc.push(field);
        counter = counter + 1;
      }
      return acc;
    } else if(counter === 0){
      return allFields[keyName];
    } else {
      return allFields[keyName];
    }
  }, []);
}

const getClientInfoValue = (globalState:any,key:any) => {
  const clientName = globalState?.clientData?.name;
  if (clientInfo.hasOwnProperty(clientName)) {
    return clientInfo[clientName][key];
  } else {
    return clientInfo['*'][key];
  }
}

export const getOnboardingImg = (globalState:any) => {
  return getClientInfoValue(globalState, 'leftImage');
}

export const getHeaderLogo = (globalState:any) => {
  return getClientInfoValue(globalState, 'headerLogo');
}

export const getHeaderImg = (globalState:any) => {
  return getClientInfoValue(globalState, 'headerImage');
}



export const ShowLogout = () => {
  const { globalState, dispatch } = useContext(globalContext);
  const { oktaAuth } = useOktaAuth();

  const logoutUser = () => {
    sessionStorage.clear();
    if (oktaAuth) {
      oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin
      });
    } else {
      window.location.reload();
    }
  };

  const closeLogoutModal = () => {
    logoutUser();
    dispatch({
      type: "SET_SHOW_LOGOUT_MODAL",
      payload: false,
    });
  }

  const ModalBody = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ paddingBottom: '20px' }}>
          Your session has expired due to inactivity. Please log in again.
        </div>
        <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
          <Button
            text="Continue"
            variant={ButtonVariant.Secondary}
            classNames="secondaryButton"
            onClick={() => {
              closeLogoutModal();
            }}
          />
        </div>
      </div>
    )
  }


  return (
    <Modal
      visible={globalState?.showLogoutModal}
      actionsClassNames="my-modal-actions-class"
      body={<ModalBody />}
      bodyClassNames="my-modal-body-class"
      bodyPadding
      configContextProps={{
        noGradientContext: false,
        noThemeContext: false
      }}
      data-test-id="my-modal-test-id"
      header="Session Expired!"
      headerClassNames="my-modal-header-class"
      modalClassNames="my-modal-class"
      modalWrapperClassNames="my-modal-wrapper-class"
      onClose={closeLogoutModal}
      overlay
      positionStrategy="absolute"
      renderContentAlways
      size={ModalSize.small}
      themeContainerId="my-modal-theme-container"
      zIndex={1000}
    />
  )
}
